<template>
  <div class="Home p-1">
    <Row :gutter="8">
      <i-col span="4">
        <div class="recoderNum bg-primary">
          <div>{{ mainStatistics.usersN }}</div>
          <div>个人用户数</div>
        </div>
      </i-col>
      <i-col span="4">
        <div class="recoderNum bg-secondary">
          <div>{{ mainStatistics.unitN }}</div>
          <div>单位用户数</div>
        </div>
      </i-col>
      <i-col span="4">
        <div class="recoderNum bg-primary">
          <div>{{ mainStatistics.assessmentN }}</div>
          <div>心理测评数</div>
        </div>
      </i-col>
      <i-col span="4">
        <div class="recoderNum bg-secondary">
          <div>{{ mainStatistics.counselingN }}</div>
          <div>心理咨询数</div>
        </div>
      </i-col>
      <i-col span="4">
        <div class="recoderNum bg-primary">
          <div>{{ mainStatistics.orderA }}</div>
          <div>订单金额（元）</div>
        </div>
      </i-col>
      <i-col span="4">
        <div class="recoderNum bg-secondary">
          <div>{{ mainStatistics.balance }}</div>
          <div>余额（元）</div>
        </div>
      </i-col>
      <i-col span="10">
        <div class="showtitle">
          最新测评记录
          <span class="color-warning">点击量表名称看报告</span>
        </div>
        <Table border stripe size="small" :columns="newAssessmentColumns" :data="newAssessment"></Table>
      </i-col>
      <i-col span="8">
        <div class="showtitle">最常用量表</div>
        <div id="scalepie" />
        <!-- <Table border stripe size="small" :columns="commonScaleColumns" :data="commonScale"></Table> -->
      </i-col>
      <i-col span="6">
        <div class="showtitle">明星咨询师</div>
        <Table border stripe size="small" :columns="starCounselorColumns" :data="starCounselor"></Table>
      </i-col>
      <i-col span="8">
        <div class="showtitle">用户年龄分布</div>
        <div id="agepie" />
        <!-- <ve-pie
          :data="agePieChart"
          :settings="ageChartSet"
          :legend-visible="false"
          :judge-width="true"
          height="16.25rem"
        ></ve-pie>-->
      </i-col>
      <i-col span="8">
        <div class="showtitle">用户学历分布</div>
        <div id="edupie" />
        <!-- <ve-pie
          :data="educationPieChart"
          :settings="educationChartSet"
          :legend-visible="false"
          :judge-width="true"
          height="16.25rem"
        ></ve-pie>-->
      </i-col>
      <i-col span="8">
        <div class="showtitle">用户职业分布</div>
        <div id="professpie" />
      </i-col>
      <i-col span="12">
        <div class="showtitle">收入统计</div>
        <ve-histogram
          :data="incomehistogramChart"
          :settings="incomeChartSet"
          :judge-width="true"
          :grid="chartGrid"
          height="18.75rem"
        ></ve-histogram>
      </i-col>
      <i-col span="12">
        <div class="showtitle">访问量统计</div>
        <ve-line
          :data="visitLineChart"
          :settings="visitChartSet"
          :judge-width="true"
          :grid="chartGrid"
          height="18.75rem"
        ></ve-line>
      </i-col>
    </Row>
  </div>
</template>

<script>
import DataSet from "@antv/data-set";
import * as G2 from "@antv/g2";
import agePieData from "../json/home_agePie.json";
import educationPieData from "../json/home_educationPie.json";
import occupationPieData from "../json/home_occupationPie.json";
import incomeHistogramData from "../json/home_incomeHistogram.json";
import visitLineData from "../json/home_visitLine.json";

const { DataView } = DataSet;
const dv = new DataView();
export default {
  name: "Home",
  data() {
    return {
      mainStatistics: {},
      newAssessmentColumns: [
        {
          title: "被测者",
          key: "userName",
          minWidth: 80,
          ellipsis: true,
        },
        {
          title: "量表",
          key: "scaleName",
          minWidth: 210,
          ellipsis: true,
        },
        {
          title: "时间",
          key: "testTime",
          render: (h, params) => {
            let dt = this.myjs.subDate(params.row.testTime);
            return h("span", dt);
          },
          minWidth: 80,
          ellipsis: true,
        },
      ],
      newAssessment: [],
      commonScaleColumns: [
        {
          title: "量表",
          key: "scaleName",
          minWidth: 220,
          ellipsis: true,
        },
        {
          title: "次数",
          key: "useTimes",
          minWidth: 60,
          ellipsis: true,
        },
      ],
      commonScale: [],
      starCounselorColumns: [
        {
          title: "姓名",
          key: "consultantName",
          minWidth: 80,
          ellipsis: true,
        },
        {
          title: "咨询时长(分钟)",
          key: "useTimes",
          minWidth: 60,
          ellipsis: true,
        },
      ],
      starCounselor: [],
      // 用户年龄分布
      ageChartSet: {
        labelMap: {
          ages: "年龄",
          number: "人数",
        },
        offsetY: "50%",
      },
      agePieChart: {
        columns: ["ages", "number"],
        rows: agePieData,
      },
      // 用户学历分布
      educationChartSet: {
        labelMap: {
          education: "学历",
          number: "人数",
        },
        offsetY: "50%",
      },
      educationPieChart: {
        columns: ["education", "number"],
        rows: educationPieData,
      },
      // 用户职业分布
      occupationChartSet: {
        labelMap: {
          occupation: "职业",
          number: "人数",
        },
        offsetY: "50%",
      },
      occupationPieChart: {
        columns: ["occupation", "number"],
        rows: occupationPieData,
      },

      chartGrid: {
        bottom: 20,
      },
      incomeChartSet: {
        labelMap: {
          scales: "心理测评",
          consult: "心理咨询",
          activity: "线下活动",
        },
        yAxisType: ["KMB"],
        yAxisName: ["元"],
      },
      incomehistogramChart: {
        columns: ["day", "scales", "consult", "activity"],
        rows: incomeHistogramData,
      },
      visitChartSet: {
        labelMap: {
          hours: "时",
          visits: "访问量",
        },
        area: true,
        yAxisType: ["KMB"],
        yAxisName: ["次"],
      },
      visitLineChart: {
        columns: ["hours", "visits"],
        rows: visitLineData,
      },

      data: [
        { type: "一线城市", value: 0.19 },
        { type: "二线城市", value: 0.21 },
        { type: "三线城市", value: 0.27 },
        { type: "四线及以下", value: 0.33 },
      ],
      pie1data: [
        {
          ages: "15岁以下",
          number: 235,
        },
        {
          ages: "16-25岁",
          number: 274,
        },
        {
          ages: "26-35岁",
          number: 310,
        },
        {
          ages: "36-45岁",
          number: 335,
        },
        {
          ages: "46-55岁",
          number: 245,
        },
        {
          ages: "56岁以上",
          number: 127,
        },
      ],
    };
  },
  mounted() {
    // 获取主要统计数据
    this.$get("General/GetMainStatistics", {
      requestName: "GetMainStatistics",
    }).then((res) => {
      this.mainStatistics = res.data.response;
    });
    // 获取最新五条测试记录
    this.$get("General/GetNewAssessmentList", {
      requestName: "GetNewAssessmentList",
    }).then((res) => {
      this.newAssessment = res.data.responseList;
    });
    // 获取最常用量表
    this.$get("General/GetCommonScaleList", {
      requestName: "GetCommonScaleList",
    }).then((res) => {
      this.commonScale = res.data.responseList;
      this.initCommonScale();
    });
    // 获取咨询次数最多咨询师
    this.$get("General/GetStarCounselorList", {
      requestName: "GetStarCounselorList",
    }).then((res) => {
      this.starCounselor = res.data.responseList;
    });

    this.initAgepie();
    this.initEdupie();
    this.initProfesspie();
  },
  methods: {
    initAgepie() {
      const chart = new G2.Chart({
        container: "agepie",
        autoFit: true,
        height: 300,
      });
      chart.data(agePieData);

      chart.coordinate("theta", {
        radius: 1,
      });
      chart.tooltip({
        showMarkers: false,
      });
      const interval = chart
        .interval()
        .adjust("stack")
        .position("number")
        .color("ages", [
          "#063d8a",
          "#1770d6",
          "#47abfc",
          "#38c060",
          "#1890ff",
          "#13c2c2",
          "#ffc53d",
          "#73d13d",
        ])
        .style({ opacity: 0.4 })
        .state({
          active: {
            style: (element) => {
              const shape = element.shape;
              return {
                matrix: G2.Util.zoom(shape, 1.1),
              };
            },
          },
        })
        .label("type", (val) => {
          return {
            offset: -30,
            style: {
              fill: "white",
              fontSize: 12,
              shadowBlur: 2,
              shadowColor: "rgba(0, 0, 0, .45)",
            },
            content: (obj) => {
              return obj.ages + "\n" + obj.number + "%";
            },
          };
        });

      chart.interaction("element-single-selected");

      chart.render();
    },
    initEdupie() {
      const chart = new G2.Chart({
        container: "edupie",
        autoFit: true,
        height: 300,
      });
      chart.data(educationPieData);

      chart.coordinate("theta", {
        radius: 1,
      });
      chart.tooltip({
        showMarkers: false,
      });

      const interval = chart
        .interval()
        .adjust("stack")
        .position("number")
        .color("education", [
          "#063d8a",
          "#1770d6",
          "#47abfc",
          "#38c060",
          "#1890ff",
          "#13c2c2",
          "#ffc53d",
          "#73d13d",
        ])
        .style({ opacity: 0.4 })
        .state({
          active: {
            style: (element) => {
              const shape = element.shape;
              return {
                matrix: G2.Util.zoom(shape, 1.1),
              };
            },
          },
        })
        .label("type", (val) => {
          return {
            offset: -30,
            style: {
              fill: "white",
              fontSize: 12,
              shadowBlur: 2,
              shadowColor: "rgba(0, 0, 0, .45)",
            },
            content: (obj) => {
              return obj.education + "\n" + obj.number + "%";
            },
          };
        });

      chart.interaction("element-single-selected");

      chart.render();
    },
    initProfesspie() {
      const chart = new G2.Chart({
        container: "professpie",
        autoFit: true,
        height: 300,
      });
      chart.data(occupationPieData);

      chart.coordinate("theta", {
        radius: 1,
      });
      chart.tooltip({
        showMarkers: false,
      });

      const interval = chart
        .interval()
        .adjust("stack")
        .position("number")
        .color("occupation", [
          "#063d8a",
          "#1770d6",
          "#47abfc",
          "#38c060",
          "#1890ff",
          "#13c2c2",
          "#ffc53d",
          "#73d13d",
        ])
        .style({ opacity: 0.4 })
        .state({
          active: {
            style: (element) => {
              const shape = element.shape;
              return {
                matrix: G2.Util.zoom(shape, 1.1),
              };
            },
          },
        })
        .label("type", (val) => {
          return {
            offset: -30,
            style: {
              fill: "white",
              fontSize: 12,
              shadowBlur: 2,
              shadowColor: "rgba(0, 0, 0, .45)",
            },
            content: (obj) => {
              return obj.occupation + "\n" + obj.number + "%";
            },
          };
        });

      chart.interaction("element-single-selected");

      chart.render();
    },
    initCommonScale() {
      const chart = new G2.Chart({
        container: "scalepie",
        autoFit: true,
        height: 300,
        padding: [40, 0],
      });

      chart.data(this.commonScale);
      chart.coordinate("polar", {
        startAngle: Math.PI, // 起始角度
        endAngle: Math.PI * (3 / 2), // 结束角度
      });

      chart.scale("scaleName", {
        tickCount: 6,
      });

      chart.axis("scaleName", {
        grid: {
          line: {
            type: "circle",
          },
          closed: false,
        },
        verticalFactor: 1,
        label: {
          offset: 5,
        },
      });

      chart.axis("scaleName", {
        tickLine: {
          alignTick: false,
        },
        grid: {
          alignTick: false,
        },
      });

      chart.tooltip({
        showMarkers: false,
      });

      chart
        .interval()
        .position("scaleName*useTimes")
        .color("scaleName", "#2d8cf0")
        .label("useTimes", {
          offset: -5,
          style: {
            textAlign: "center",
            fill: "#000",
          },
        })
        .style({
          lineWidth: 1,
          stroke: "#fff",
        });
      chart.render();
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.recoderNum {
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  div:first-child {
    font-size: 2rem;
    color: white;
    line-height: 2rem;
  }

  div:last-child {
    font-size: 0.8rem;
    color: white;
  }
}

.showtitle {
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: white;
  padding: 0rem 0.5rem;
  margin-top: 0.5rem;
  text-align: left;
  span {
    float: right;
  }
}
.ivu-table-wrapper-with-border {
  border-radius: 4px;
}
.ivu-table {
  font-size: 1rem !important;
}

.ve-pie,
.ve-histogram,
.ve-line {
  background-color: white;
}
</style>
